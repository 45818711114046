import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import {APP_ROUTES} from '../../constants';
import {Pages} from '../../types';
import {ProtectionType} from './types';

type Props = {
  element: JSX.Element;
  protectionType: typeof ProtectionType[keyof typeof ProtectionType];
};

const ProtectedRoute: React.FC<Props> = ({element, protectionType}) => {
  const location = useLocation();
  if (!localStorage.getItem('token') && location.pathname !== APP_ROUTES[Pages.SignIn]) {
    return <Navigate state={{from: location}} to={APP_ROUTES[Pages.SignIn]} replace />;
  }

  return element;
};

export default ProtectedRoute;
