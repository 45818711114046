import * as React from 'react';
import {useNavigate} from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {CssBaseline, TextField, Paper, Box, Grid, Typography, createTheme, ThemeProvider} from '@mui/material';

import axios from 'api/axios';
import {APP_ROUTES} from 'components/router/constants';
import {Pages} from 'components/router/types';
import {useState} from 'react';

const theme = createTheme();

export const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    axios
      .post('/auth/login', {
        username: data.get('email'),
        password: data.get('password'),
      })
      .then((data: any) => {
        const token = data.data.token;
        window.localStorage.setItem('token', token);
        navigate(APP_ROUTES[Pages.Users], {replace: true});
        setLoading(false)
      })
      .catch((err: any) => {
        setLoading(false);
        NotificationManager.error(err.response.data.message);
      })
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component='main' sx={{height: '100vh'}}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(https://images.unsplash.com/photo-1560252719-59e35a3bbc6d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzF8fGdhbWluZ3xlbnwwfHwwfHw%3D&w=1000&q=80)`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#201941',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Typography component='h1' variant='h5'>
              Sign in
            </Typography>
            <Box component='form' noValidate onSubmit={handleSubmit} sx={{mt: 1}}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                autoFocus
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
              />
              <LoadingButton color='warning' loading={loading} type='submit' fullWidth variant='contained' sx={{mt: 3, mb: 2}}>
                Sign In
              </LoadingButton>
              <NotificationContainer/>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default SignIn;
