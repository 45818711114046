import React, {useEffect, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button, CircularProgress
} from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';

import axios from 'api/axios';
import {Grid} from '@mui/material';

import styles from './styles.module.css';

export const Users = () => {
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [isShowUsers, setIsShowUsers] = useState(false);

  const getTeams = () => {
    setLoading(true);
    axios.get('/api/v1/my/teams')
      .then((data: any) => {
        setTeams(data.data.teams);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getUsers = (id: string | number) => {
    setLoading(true);
    axios.get(`/public/v1/team/${id}/members`)
      .then((data: any) => {
        console.log(data.data.members);
        setUsers(data.data.members)
        setLoading(false);
        setIsShowUsers(true);
      })
      .catch(() => setLoading(false));
  }

  const selectTeam = (id: string | number) => {
    getUsers(id);
  }


  useEffect(() => {
    getTeams();
  }, []);

  if (loading) {
    return (
      <Grid height='100%' padding={4} display='flex' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Grid>
    )
  }

  return <Grid padding={4}>
    {isShowUsers ? (
      <>
        <Grid display='flex' justifyContent='flex-start' marginBottom={2}>
          <Button variant="outlined" onClick={() => setIsShowUsers(false)}>Back</Button>
        </Grid>
        <Typography textAlign='left' fontWeight={800} fontSize={28} marginBottom={2}>Users</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Avatar</TableCell>
                <TableCell>Nickname</TableCell>
                <TableCell>Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row: any) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.avatar
                      ? <img className={styles.img} src={row.avatar}/>
                      : <Grid className={styles.imgContainer}>
                          <PermIdentityIcon />
                        </Grid>
                    }
                  </TableCell>
                  <TableCell>{row.nickname}</TableCell>
                  <TableCell>{row.role}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ) : (
      <>
        <Typography textAlign='left' fontWeight={800} fontSize={28} marginBottom={2}>Team</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Logo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams.map((row: any) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <img className={styles.img} src={row.logo}/>
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.role}</TableCell>
                  <TableCell align="right">
                    <Button variant="outlined" color='warning' onClick={() => getUsers(row.id)}>
                      Show users
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )}
  </Grid>;
};

export default Users;
