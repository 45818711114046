import * as React from 'react';
import PeopleIcon from '@mui/icons-material/People';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

import {Pages} from 'components/router/types';
import {APP_ROUTES} from 'components/router/constants';

export const Menu = [
  {
    id: 1,
    title: 'Users',
    icon: <PeopleIcon />,
    link: APP_ROUTES[Pages.Users]
  },
  {
    id: 2,
    title: 'Tournaments',
    icon: <SportsEsportsIcon />,
    link: APP_ROUTES[Pages.Tournaments]
  }
]
