import * as React from 'react';
import {
  ListItem,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Link, Divider
} from '@mui/material';

import { Menu } from './constants';
import {useLocation} from 'react-router-dom';

export const MainMenu = () => {
  const location = useLocation();

  return <List>
    {Menu.map((item) => (
      <Link href={item.link} underline='none' key={item.title}>
        <ListItem disablePadding selected={location.pathname === item.link}>
          <ListItemButton>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <Typography color='black'>
              <ListItemText primary={item.title} />
            </Typography>
          </ListItemButton>
        </ListItem>
      </Link>
    ))}
    <Divider />
  </List>
}
export default MainMenu;
