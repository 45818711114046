import React from 'react';
import {Route, Routes} from 'react-router-dom';

import {SignIn, Users, Tournaments} from '../pages';
import {ProtectedRoute} from './components';
import {APP_ROUTES} from './constants';
import {Pages} from './types';

export const Router: React.FC = () => (
  <Routes>
    <Route path={APP_ROUTES[Pages.Base]} element={<ProtectedRoute protectionType='user' element={<Users />} />} />

    <Route path={APP_ROUTES[Pages.Users]} element={<ProtectedRoute protectionType='user' element={<Users />} />} />

    <Route path={APP_ROUTES[Pages.SignIn]} element={<ProtectedRoute protectionType='guest' element={<SignIn />} />} />

    <Route path={APP_ROUTES[Pages.Tournaments]} element={<ProtectedRoute protectionType='user' element={<Tournaments />} />} />

    <Route path='*' element={<div>not found</div>} />
  </Routes>
);

export default Router;
