import React, {useEffect, useState} from 'react';
import {
  Grid,
  CircularProgress,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, TableContainer
} from '@mui/material';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

import axios from 'api/axios';

import styles from './styles.module.css';

export const Tournaments = () => {
  const [loading, setLoading] = useState(false);
  const [tournaments, setTournaments] = useState([]);

  const getTournaments = () => {
    setLoading(true);
    axios.get(`/public/v1/tournaments`)
      .then((data: any) => {
        console.log(data.data.tournaments);
        setTournaments(data.data.tournaments)
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  useEffect(() => {
    getTournaments();
  }, []);

  if (loading) {
    return (
      <Grid height='100%' padding={4} display='flex' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Grid>
    )
  }

  return <Grid padding={4}>
    <Typography textAlign='left' fontWeight={800} fontSize={28} marginBottom={2}>Tournaments</Typography>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Game title</TableCell>
            <TableCell>Game logo</TableCell>
            <TableCell align='center'>Participant Limit</TableCell>
            <TableCell align='right'>Participation Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tournaments.map((row: any) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.game.title}</TableCell>
              <TableCell>
                {row.game.logo
                  ? <img src={row.game.logo} className={styles.gameLogo} />
                  : <Grid className={styles.imgContainer}>
                      <NoPhotographyIcon />
                    </Grid>
                }
              </TableCell>
              <TableCell align='center'>{row.participantLimit}</TableCell>
              <TableCell align='right'>{row.participationType}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
}
export default Tournaments;
