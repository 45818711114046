import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import Router from '../router';
import {Grid} from '@mui/material';
import Header from './components/Header';
import MainMenu from './components/Menu';

import styles from './styles.module.css';

const Layout: React.FC = () => {
  const [isAuth, setIsAuth] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    setIsAuth(!!token);
  }, [window.localStorage.getItem('token'), location.pathname]);

  return <Grid minHeight='100%' flex='1' display='flex' flexDirection='column'>
    {isAuth && <Header />}
    <Grid minHeight='100%' container className={styles.menu}>
      {isAuth && (
        <Grid xs={12} sm={4} lg={2} sx={{ backgroundColor: '#f3f3f3' }}>
          <MainMenu />
        </Grid>
      )}

      <Grid xs={12} sm={isAuth ? 8 : 12} lg={isAuth ? 10 : 12}>
        <Router />
      </Grid>
    </Grid>
  </Grid>
};

export default Layout;
