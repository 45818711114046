import * as React from 'react';
import {Box, styled, Button, Grid} from '@mui/material';
import {NavLink, useNavigate} from 'react-router-dom';

import {APP_ROUTES} from 'components/router/constants';
import {Pages} from 'components/router/types';
import logo from 'assets/logo.png';

const Img = styled('img')({
  width: '100%'
});

export const Header = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('token');
    return navigate(APP_ROUTES[Pages.Users], {replace: true});
  }

  return (<Box color='white' display='flex' alignItems='center' justifyContent='space-between' sx={{ background: '#201941'}} padding={2}>
      <NavLink to='/'>
        <Grid maxWidth='300px' marginRight='2rem'>
          <Img src={logo} />
        </Grid>
      </NavLink>
      <Button variant="outlined" color="inherit" onClick={logout}>Logout</Button>
  </Box>)
};

export default Header;
